<template>
  <v-container>
    <v-card class="mx-auto cardstyle" max-width="800" raised>
    <v-img
      height="250"
      src="/static/header.jpg"
    ></v-img>      
      <v-card-title>Inscripción GIRA Mujeres</v-card-title>
    <v-card-text>
      <p class="text-body-1">¡Gracias por tu tiempo! Te confirmamos que tu formulario ha sido registrado con éxito. En las próximas semanas y en el caso de ser seleccionada te contactaremos desde el equipo de GIRA Mujeres para contarte la noticia. </p>
      <p class="text-body-1">¡Hasta pronto!</p>
      <p class="text-body-1"><strong>Equipo GIRA Mujeres de Coca-Cola</strong></p>
    </v-card-text>
  </v-card>
    <v-container>
      <v-row>
        <v-col class="text-center"><a href="/static/bases-v-edicion-giramujeres.pdf" target="_blank">BASES DE PARTICIPACIÓN V EDICIÓN GIRA MUJERES DE COCA-COLA</a></v-col>
      </v-row>
      <v-row>
        <v-col class="text-center"><a href="/static/folleto-cadena-valor.pdf" target="_blank">FOLLETO CADENA DE VALOR</a></v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'

export const db = firebase
  .initializeApp({ databaseURL: 'https://giramujeres-preinscripcion.firebaseio.com' })
  .database()

export default {
  mounted: function () {
    this.submit()
  },
  methods: {
        encodeAsFirebaseKey: function (string) {
      return string.replace(/%/g, '%25')
        .replace(/\./g, '%2E')
        .replace(/#/g, '%23')
        .replace(/\$/g, '%24')
        .replace(/\//g, '%2F')
        .replace(/\[/g, '%5B')
        .replace(/\]/g, '%5D')
    },
    submit: async function () {
        let ref = firebase.database().ref('/preinscripciones-2021/' + this.encodeAsFirebaseKey(this.$store.state.registryField_7146.trim().toLowerCase()))
        try {
            await ref.set(this.$store.state)
        } catch (error) {
          if (error.code === 'PERMISSION_DENIED') {
            alert('Este email ya esta preinscrito.');
          } else {
            alert('Error realizando preinscripción. Si el error persiste ponte en contacto con nosotros.')
          }

        }

    }

  }
}
</script>
